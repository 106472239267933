export const paymentStatusMap = {
  awaitingPayment: {
    label: "Awaiting Payment",
    backgroundColor: "default",
    status: 0,
  },
  paid: { label: "Paid", backgroundColor: "success", status: 1 },
  voided: { label: "Voided", backgroundColor: "warning", status: 2 },
  partiallyPaid: {
    label: "Partially Paid",
    backgroundColor: "info",
    status: 3,
  },
  paidInCash: { label: "Paid in Cash", backgroundColor: "success", status: 4 },
};

export const paymentStatusNumberMap = {
  0: paymentStatusMap.awaitingPayment,
  1: paymentStatusMap.paid,
  2: paymentStatusMap.voided,
  3: paymentStatusMap.partiallyPaid,
  4: paymentStatusMap.paidInCash,
};

export const rowVersionMap = {
  smxWeb: { label: "SMX Web", color: "primary", status: 0 },
  mobileClient: { label: "Mobile Client", color: "warning", status: 1 },
  market: { label: "Market", color: "default", status: 2 },
  externalSystem: { label: "External System", color: "default", status: 3 },
  sys: { label: "SYS", color: "error", status: 4 },
  portalxWeb: { label: "PortalX Web", color: "secondary", status: 5 },
  portalxMobile: { label: "PortalX Mobile", color: "secondary", status: 6 },
  unknown: { label: "Unknown", color: "default", status: 7 },
  businessCentral: { label: "Business Central", color: "success", status: 8 },
  sage: { label: "Sage", color: "default", status: 9 },
  sap: { label: "SAP", color: "default", status: 10 },
  quickBooks: { label: "QuickBooks", color: "default", status: 11 },
};

export const rowVersionNumberMap = {
  0: rowVersionMap.smxWeb,
  1: rowVersionMap.mobileClient,
  2: rowVersionMap.market,
  3: rowVersionMap.externalSystem,
  4: rowVersionMap.sys,
  5: rowVersionMap.portalxWeb,
  6: rowVersionMap.portalxMobile,
  7: rowVersionMap.unknown,
  8: rowVersionMap.businessCentral,
  9: rowVersionMap.sage,
  10: rowVersionMap.sap,
  11: rowVersionMap.quickBooks,
};

export const userStatusMap = {
  active: { label: "Active", color: "success", status: 0 },
  onHold: { label: "On Hold", color: "error", status: 1 },
  hidden: { label: "Hidden", color: "warning", status: 2 },
};

export const userStatusNumberMap = {
  0: userStatusMap.active,
  1: userStatusMap.onHold,
  2: userStatusMap.hidden,
};

export const userTypeMap = {
  internal: { label: "Internal", color: "primary", status: 0 },
  portalx: { label: "PortalX", color: "success", status: 1 },
  abix: { label: "ABIX", color: "secondary", status: 4 },
};

export const userTypeNumberMap = {
  0: userTypeMap.internal,
  1: userTypeMap.portalx,
  4: userTypeMap.abix,
};

export const orderStatusMap = {
  open: {
    status: 0,
    label: "Open",
    backgroundColor: "success",
  },
  confirmed: {
    status: 1,
    label: "Confirmed",
    backgroundColor: "primary",
  },
  released: {
    status: 2,
    label: "Released",
    backgroundColor: "primary",
  },
  partiallyReleased: {
    status: 3,
    label: "Partially Released",
    backgroundColor: "default",
  },
  cancelled: {
    status: 4,
    label: "Cancelled",
    backgroundColor: "error",
  },
  pendingPrepayment: {
    status: 5,
    label: "Pending Prepayment",
    backgroundColor: "default",
  },
  onHold: {
    status: 6,
    label: "On Hold",
    backgroundColor: "warning",
  },
  pendingApproval: {
    status: 7,
    label: "Pending Approval",
    backgroundColor: "default",
  },
  completed: {
    status: 8,
    label: "Completed",
    backgroundColor: "primary",
  },
};

export const orderStatusNumberMap = {
  0: orderStatusMap.open,
  1: orderStatusMap.confirmed,
  2: orderStatusMap.released,
  3: orderStatusMap.partiallyReleased,
  4: orderStatusMap.cancelled,
  5: orderStatusMap.pendingPrepayment,
  6: orderStatusMap.onHold,
  7: orderStatusMap.pendingApproval,
  8: orderStatusMap.completed,
};

export const quoteStatusMap = {
  open: { label: "Open", backgroundColor: "success", status: 0 }, //Open
  won: { label: "Won", backgroundColor: "success", status: 1 }, //Won
  lost: { label: "Lost", backgroundColor: "error", status: 2 }, //Lost
  expired: { label: "Expired", backgroundColor: "warning", status: 3 }, //Expired
  pending: { label: "Pending", backgroundColor: "default", status: 4 }, //Pending
  approvedByCustomer: {
    label: "Approved by Customer",
    backgroundColor: "primary",
    status: 5,
  }, //Approved by Customer
};

export const quoteStatusNumberMap = {
  0: quoteStatusMap.open,
  1: quoteStatusMap.won,
  2: quoteStatusMap.lost,
  3: quoteStatusMap.expired,
  4: quoteStatusMap.pending,
  5: quoteStatusMap.approvedByCustomer,
};

export const invoiceStatusMap = {
  open: { label: "Open", backgroundColor: "success", status: 0 }, //Open
  invoiced: { label: "Invoiced", backgroundColor: "primary", status: 1 }, //Invoiced
  cancelled: { label: "Cancelled", backgroundColor: "error", status: 2 }, //Cancelled
  pending: { label: "Pending", backgroundColor: "default", status: 3 }, //Pending
};

export const invoiceStatusNumberMap = {
  0: invoiceStatusMap.open,
  1: invoiceStatusMap.invoiced,
  2: invoiceStatusMap.cancelled,
  3: invoiceStatusMap.pending,
};
