import React, { lazy, Suspense, useEffect } from "react";
import axios from "axios";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
  useNavigate,
} from "react-router-dom";
import { CircularProgress, Typography } from "@mui/material";
import { useAppState } from "./reduxModule";

import Dashboard from "./components/Dashboard/Dashboard";

import LoginView from "./components/Authentication/LoginPageView";
import ResetPasswordView from "./components/Authentication/ResetPasswordView";
import ForgotPasswordView from "./components/Authentication/ForgotPasswordView";

export const AppRoutes = () => {
  // Lazy load components
  const CustomerListView = lazy(() =>
    import("./components/Views/Customer/CustomerList")
  );
  const CustomerDetailView = lazy(() =>
    import("./components/Views/Customer/CustomerDetail")
  );

  const DeliveryZoneListView = lazy(() =>
    import("./components/Views/Settings/DeliveryZone/DeliveryZoneListView")
  );
  const DeliveryZoneDetailView = lazy(() =>
    import("./components/Views/Settings/DeliveryZone/DeliveryZoneDetailView")
  );

  const DeliveryChargeListView = lazy(() =>
    import("./components/Views/Settings/DeliveryCharge/DeliveryChargeListView")
  );
  const DeliveryChargeDetailView = lazy(() =>
    import(
      "./components/Views/Settings/DeliveryCharge/DeliveryChargeDetailView"
    )
  );

  const UserListView = lazy(() => import("./components/Views/User/UserList"));
  const CustomerNewView = lazy(() =>
    import("./components/Views/Customer/CustomerNew")
  );
  const UserNewPortalX = lazy(() =>
    import("./components/Views/User/UserNewPortalX")
  );
  const UserNewInternal = lazy(() =>
    import("./components/Views/User/UserNewInternal")
  );
  const UserDetailView = lazy(() =>
    import("./components/Views/User/UserDetail")
  );
  const ItemListView = lazy(() => import("./components/Views/Item/ItemList"));
  const ItemDetailView = lazy(() =>
    import("./components/Views/Item/ItemDetail")
  );
  const ItemNewView = lazy(() => import("./components/Views/Item/ItemNew"));
  const ContactDetailView = lazy(() =>
    import("./components/Views/Contact/ContactDetail")
  );
  const ContactListView = lazy(() =>
    import("./components/Views/Contact/ContactList")
  );

  const MyTaskDetailView = lazy(() =>
    import("./components/Views/MyTask/MyTaskDetail")
  );
  const MyTaskListView = lazy(() =>
    import("./components/Views/MyTask/MyTaskList")
  );

  const OrderListView = lazy(() =>
    import("./components/Views/SalesDocument/Order/OrderListView")
  );

  const OrderDetailView = lazy(() =>
    import("./components/Views/SalesDocument/Order/OrderDetailView")
  );

  const ReturnOrderListView = lazy(() =>
    import("./components/Views/SalesDocument/ReturnOrder/ReturnOrderListView")
  );
  const ReturnOrderDetailView = lazy(() =>
    import("./components/Views/SalesDocument/ReturnOrder/ReturnOrderDetailView")
  );

  const CreditMemoListView = lazy(() =>
    import("./components/Views/SalesDocument/CreditMemo/CreditMemoListView")
  );
  const CreditMemoDetailView = lazy(() =>
    import("./components/Views/SalesDocument/CreditMemo/CreditMemoDetailView")
  );

  const QuoteListView = lazy(() =>
    import("./components/Views/SalesDocument/Quote/QuoteListView")
  );
  const QuoteDetailView = lazy(() =>
    import("./components/Views/SalesDocument/Quote/QuoteDetailView")
  );

   const QuoteHistoryListView = lazy(() =>
     import("./components/Views/SalesDocumentHistory/Quote/QuoteListView")
   );
   const QuoteHistoryDetailView = lazy(() =>
     import("./components/Views/SalesDocumentHistory/Quote/QuoteDetailView")
   );

  const InvoiceListView = lazy(() =>
    import("./components/Views/SalesDocument/Invoice/InvoiceListView")
  );
  const InvoiceDetailView = lazy(() =>
    import("./components/Views/SalesDocument/Invoice/InvoiceDetailView")
  );

   const InvoiceHistoryListView = lazy(() =>
     import("./components/Views/SalesDocumentHistory/Invoice/InvoiceListView")
   );
   const InvoiceHistoryDetailView = lazy(() =>
     import("./components/Views/SalesDocumentHistory/Invoice/InvoiceDetailView")
   );



   const ReturnOrderHistoryListView = lazy(() =>
     import("./components/Views/SalesDocumentHistory/ReturnOrder/ReturnOrderListView")
   );
   const ReturnOrderHistoryDetailView = lazy(() =>
     import("./components/Views/SalesDocumentHistory/ReturnOrder/ReturnOrderDetailView")
   );



  const BasketListView = lazy(() =>
    import("./components/Views/SalesDocument/Basket/BasketListView")
  );
  const BasketDetailView = lazy(() =>
    import("./components/Views/SalesDocument/Basket/BasketDetailView")
  );

    const BasketHistoryListView = lazy(() =>
      import("./components/Views/SalesDocumentHistory/Basket/BasketListView")
    );
    const BasketHistoryDetailView = lazy(() =>
      import("./components/Views/SalesDocumentHistory/Basket/BasketDetailView")
    );

       const OrderHistoryListView = lazy(() =>
         import("./components/Views/SalesDocumentHistory/Order/OrderListView")
       );
       const OrderHistoryDetailView = lazy(() =>
         import(
           "./components/Views/SalesDocumentHistory/Order/OrderDetailView"
         )
       );

  const ImageLibraryList = lazy(() =>
    import("./components/Views/Settings/ImageLibrary/ImageLibraryList")
  );
  const SettingsView = lazy(() =>
    import("./components/Views/Settings/SettingsView")
  );

  const LocationListView = lazy(() =>
    import("./components/Views/Settings/Location/LocationListView")
  );
  const LocationDetailView = lazy(() =>
    import("./components/Views/Settings/Location/LocationDetailView")
  );

    const StoreLocationListView = lazy(() =>
      import("./components/Views/Settings/StoreLocation/StoreLocationListView")
    );
    const StoreLocationDetailView = lazy(() =>
      import("./components/Views/Settings/StoreLocation/StoreLocationDetailView")
    );

  const PaymentTransactionListView = lazy(() =>
    import(
      "./components/Views/Settings/PaymentTransactions/PaymentTransactionListView"
    )
  );
  const SalesTeamListView = lazy(() =>
    import("./components/Views/Settings/SalesTeam/SalesTeamListView")
  );
  const SalesTeamDetailView = lazy(() =>
    import("./components/Views/Settings/SalesTeam/SalesTeamDetailView")
  );

    const DisplayCaseListView = lazy(() =>
      import("./components/Views/Settings/DisplayCase/DisplayCaseListView")
    );
    const DisplayCaseDetailView = lazy(() =>
      import("./components/Views/Settings/DisplayCase/DisplayCaseDetailView")
    );

  const CustomerDeliveryChargeGroupListView = lazy(() =>
    import(
      "./components/Views/Settings/CustomerDeliveryChargeGroup/CustomerDeliveryChargeGroupListView"
    )
  );
  const CustomerDeliveryChargeGroupDetailView = lazy(() =>
    import(
      "./components/Views/Settings/CustomerDeliveryChargeGroup/CustomerDeliveryChargeGroupDetailView"
    )
  );

  const CollectionMenuGroupListView = lazy(() =>
    import(
      "./components/Views/Settings/CollectionMenuGroup/CollectionMenuGroupListView"
    )
  );
  const CollectionMenuGroupDetailView = lazy(() =>
    import(
      "./components/Views/Settings/CollectionMenuGroup/CollectionMenuGroupDetailView"
    )
  );
  const PowerBiListView = lazy(() =>
    import("./components/Views/Settings/PowerBiTeam/PowerBiListView")
  );
  const PowerBiDetailView = lazy(() =>
    import("./components/Views/Settings/PowerBiTeam/PowerBiDetailView")
  );

  const PowerBiReportListView = lazy(() =>
    import("./components/Views/Settings/PowerBiReport/PowerBiReportListView")
  );
  const PowerBiReportDetailView = lazy(() =>
    import("./components/Views/Settings/PowerBiReport/PowerBiReportDetailView")
  );

  const CollectionListView = lazy(() =>
    import("./components/Views/Collection/CollectionList")
  );

  const CollectionItemListListView = lazy(() =>
    import("./components/Views/Collection/CollectionItemList")
  );

  const WarehouseLocationListView = lazy(() =>
    import(
      "./components/Views/Settings/WarehouseLocation/WarehouseLocationListView"
    )
  );

  const WarehouseLocationDetailView = lazy(() =>
    import(
      "./components/Views/Settings/WarehouseLocation/WarehouseLocationDetailView"
    )
  );


  const PaymentTermView = lazy(() =>
    import("./components/Views/Settings/PaymentTerm/PaymentTermView")
  );

  const CustomerDiscountGroupView = lazy(() =>
    import(
      "./components/Views/Settings/CustomerDiscountGroup/CustomerDiscountGroupView"
    )
  );


  const ItemDiscountGroupView = lazy(() =>
    import(
      "./components/Views/Settings/ItemDiscountGroup/ItemDiscountGroupView"
    )
  );


  const CountryRegionView = lazy(() =>
    import("./components/Views/Settings/CountryRegion/CountryRegionView")
  );

  const CountyView = lazy(() =>
    import("./components/Views/Settings/County/CountyView")
  );

  const CustomerPriceGroupView = lazy(() =>
    import(
      "./components/Views/Settings/CustomerPriceGroup/CustomerPriceGroupView"
    )
  );

    const CustomerVatGroupView = lazy(() =>
      import("./components/Views/Settings/CustomerVatGroup/CustomerVatGroupView")
    );

  const ItemVatGroupView = lazy(() =>
    import("./components/Views/Settings/ItemVatGroup/ItemVatGroupView")
  );

  const TerritoryView = lazy(() =>
    import("./components/Views/Settings/Territory/TerritoryView")
  );


  const UnitOfMeasureView = lazy(() =>
    import("./components/Views/Settings/UnitOfMeasure/UnitOfMeasureView")
  );


  const VisitPurposeView = lazy(() =>
    import("./components/Views/Settings/VisitPurpose/VisitPurposeView")
  );

  const PortalxWebsiteView = lazy(() =>
    import("./components/Views/Settings/PortalxWebsite/PortalxWebsiteView")
  );

    const OutcomeView = lazy(() =>
      import("./components/Views/Settings/Outcome/OutcomeView")
    );


    const TaskTypeView = lazy(() =>
      import("./components/Views/Settings/TaskType/TaskTypeView")
    );




  const DimensionView = lazy(() =>
    import("./components/Views/Settings/DimensionType/DimensionTypeView")
  );



  const ItemUnitOfMeasureView = lazy(() =>
    import("./components/Views/Settings/ItemUnitOfMeasure/ItemUnitOfMeasureView")
  );

  const DeliveryMethodView = lazy(() =>
    import("./components/Views/Settings/DeliveryMethod/DeliveryMethodView")
  );

  const ShippingAgentServiceView = lazy(() =>
    import(
      "./components/Views/Settings/ShippingAgentService/ShippingAgentServiceView"
    )
  );

  const CurrencyView = lazy(() =>
    import("./components/Views/Settings/Currency/CurrencyView")
  );

  const WarehouseItemAvailabilityView = lazy(() =>
    import(
      "./components/Views/Settings/WarehouseItemAvailability/WarehouseItemAvailabilityView"
    )
  );

  const CustomerGroupView = lazy(() =>
    import("./components/Views/Settings/CustomerGroup/CustomerGroupView")
  );

    const NumberSeriesView = lazy(() =>
      import("./components/Views/Settings/NumberSeries/NumberSeriesView")
    );


  const SalesPriceListView = lazy(() => import("./components/Views/Settings/SalesPrice/SalesPriceList"));
  const SalesPriceNewView = lazy(() => import("./components/Views/Settings/SalesPrice/SalesPriceNew"));
    
  const SalesPriceDetailView = lazy(() =>
    import("./components/Views/Settings/SalesPrice/SalesPriceDetail")
  );

    const SalesDiscountListView = lazy(() =>
      import("./components/Views/Settings/SalesDiscount/SalesDiscountList")
    );
    const SalesDiscountNewView = lazy(() =>
      import("./components/Views/Settings/SalesDiscount/SalesDiscountNew")
    );
      
  const SalesDiscountDetailView = lazy(() =>
    import("./components/Views/Settings/SalesDiscount/SalesDiscountDetail")
  );
  


  const navigate = useNavigate();
  const { sideMenuOpen, handleUpdateSideMenuOpen } = useAppState();

  const setupAxiosInterceptors = (navigate) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          const currentPath = window.location.pathname;
          if (!currentPath.startsWith("/reset-password")) {
            navigate("/login");
          }
        }
        return Promise.reject(error);
      }
    );
  };

  useEffect(() => {
    setupAxiosInterceptors(navigate);

    //Close the side menu
    handleUpdateSideMenuOpen(false);
  }, [navigate]);

  // Loading fallback component
  const LoadingFallback = () => (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </div>
  );

  return (
    <Suspense fallback={<LoadingFallback />}>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />

        <Route
          path="/customers"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CustomerListView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/customers/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CustomerDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/delivery-zones"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <DeliveryZoneListView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/delivery-zones/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <DeliveryZoneDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/delivery-charges"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <DeliveryChargeListView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/delivery-charges/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <DeliveryChargeDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/items"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ItemListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/items/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ItemDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/items/new"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ItemNewView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/users/new/portalx"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <UserNewPortalX />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/users/new/internal"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <UserNewInternal />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/orders"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <OrderListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/orders/:id"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <OrderListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/orders/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <OrderDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/return-orders"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ReturnOrderListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/return-orders/:id"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ReturnOrderListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/return-orders/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ReturnOrderDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/return-order-history"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ReturnOrderHistoryListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/return-order-history/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ReturnOrderHistoryDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/quotes"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <QuoteListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/quotes/:id"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <QuoteListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/quotes/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <QuoteDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/quote-history"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <QuoteHistoryListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/quote-history/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <QuoteHistoryDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/invoice-history"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <InvoiceHistoryListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/invoice-history/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <InvoiceHistoryDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/invoices"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <InvoiceListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/invoices/:id"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <InvoiceListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/invoices/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <InvoiceDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/baskets"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <BasketListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/baskets/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <BasketDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/basket-history"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <BasketHistoryListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/basket-history/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <BasketHistoryDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/order-history"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <OrderHistoryListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/order-history/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <OrderHistoryDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/users/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <UserDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/contacts"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ContactListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/contacts/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ContactDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/my-tasks"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <MyTaskListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/my-tasks/:id"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <MyTaskListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/my-tasks/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <MyTaskDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/displaycases/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <Typography>TODO: Display Cases Route</Typography>
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/collections"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CollectionListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/collections/:collectionId"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CollectionListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/collections/items/:collectionId"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CollectionItemListListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/locations"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <LocationListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/locations/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <LocationDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/store-locations"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <StoreLocationListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/store-locations/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <StoreLocationDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/image-library"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <ImageLibraryList />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/payment-transactions"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <PaymentTransactionListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/sales-teams"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesTeamListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/sales-teams/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesTeamDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/display-cases"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <DisplayCaseListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/display-cases/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <DisplayCaseDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/collection-menu-groups"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CollectionMenuGroupListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/collection-menu-groups/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CollectionMenuGroupDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/warehouse-item-availability"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <WarehouseItemAvailabilityView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/powerbi-teams"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <PowerBiListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/powerbi-teams/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <PowerBiDetailView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/powerbi-reports"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <PowerBiReportListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/powerbi-reports/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <PowerBiReportDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/county-setup"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountyView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/currency-setup"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CurrencyView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/country-region-setup"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CountryRegionView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/warehouse-locations"
          element={
            <Dashboard>
              <WarehouseLocationListView />
            </Dashboard>
          }
        />
        <Route
          path="/settings/warehouse-locations/:idType/:objectId/"
          element={
            <Dashboard>
              <WarehouseLocationDetailView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/payment-terms"
          element={
            <Dashboard>
              <PaymentTermView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/customer-discount-groups"
          element={
            <Dashboard>
              <CustomerDiscountGroupView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/item-discount-groups"
          element={
            <Dashboard>
              <ItemDiscountGroupView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/customer-price-groups"
          element={
            <Dashboard>
              <CustomerPriceGroupView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/customer-vat-groups"
          element={
            <Dashboard>
              <CustomerVatGroupView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/item-vat-groups"
          element={
            <Dashboard>
              <ItemVatGroupView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/territories"
          element={
            <Dashboard>
              <TerritoryView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/unit-of-measure"
          element={
            <Dashboard>
              <UnitOfMeasureView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/visit-purpose"
          element={
            <Dashboard>
              <VisitPurposeView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/portalx-websites"
          element={
            <Dashboard>
              <PortalxWebsiteView />
            </Dashboard>
          }
        />
        <Route
          path="/settings/outcomes"
          element={
            <Dashboard>
              <OutcomeView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/task-types"
          element={
            <Dashboard>
              <TaskTypeView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/dimension-types"
          element={
            <Dashboard>
              <DimensionView />
            </Dashboard>
          }
        />
        <Route
          path="/settings/item-unit-of-measure"
          element={
            <Dashboard>
              <ItemUnitOfMeasureView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/delivery-methods"
          element={
            <Dashboard>
              <DeliveryMethodView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/shipping-agent-services"
          element={
            <Dashboard>
              <ShippingAgentServiceView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/customer-groups"
          element={
            <Dashboard>
              <CustomerGroupView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/number-series"
          element={
            <Dashboard>
              <NumberSeriesView />
            </Dashboard>
          }
        />

        <Route
          path="/settings/customer-delivery-charge-groups"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CustomerDeliveryChargeGroupListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/customer-delivery-charge-groups/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <CustomerDeliveryChargeGroupDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/sales-price"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesPriceListView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/sales-price/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesPriceDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/sales-price/new"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesPriceNewView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/sales-discount"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesDiscountListView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/sales-discount/:idType/:objectId/"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesDiscountDetailView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/settings/sales-discount/new"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SalesDiscountNewView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route
          path="/settings/*"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <SettingsView />
              </Suspense>
            </Dashboard>
          }
        />
        <Route
          path="/users"
          element={
            <Dashboard>
              <Suspense fallback={<LoadingFallback />}>
                <UserListView />
              </Suspense>
            </Dashboard>
          }
        />

        <Route path="/login" element={<LoginView />} />
        <Route path="/reset-password/:token" element={<ResetPasswordView />} />
        <Route path="/forgot-password/" element={<ForgotPasswordView />} />
        <Route path="/" element={<Navigate to="/login" />} />
      </Routes>
    </Suspense>
  );
};
