import * as React from "react";
import Grid from "@mui/material/Grid2";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Copyright from "../../internals/components/Copyright";
import HighlightedCard from "../Admin/HighlightedCard";
import PageViewsBarChart from "../Admin/PageViewsBarChart";
import SessionsChart from "../Admin/SessionsChart";
import CustomerInfoCard from "./StatCards/CustomerInfoCard";
import ItemInfoCard from "./StatCards/ItemInfoCard";
import OrderInfoCard from "./StatCards/OrderInfoCard";
import QuoteInfoCard from "./StatCards/QuoteInfoCard";
import TextCard from "./StatCards/TextCard";
import DashboardOrderList from "./DashBoardOrders";
import { useAppState } from "../../reduxModule";
import MyTasksCard from "../Admin/MyTasksCard";

export default function MainGrid() {
  //SMX State Hooks
  const { handleUpdateBreadcrumbs } = useAppState();

  React.useEffect(() => {
    console.log('Updating breadcrumbs in MainGrid'); // Add this line
    handleUpdateBreadcrumbs([
      { title: "Home", url: "/dashboard" }
    ]);
  }, []); 

  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: { sm: "100%", md: "1700px" },
      }}
    >
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, md: 12, lg: 9 }}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Overview
          </Typography>
          <Grid
            container
            spacing={2}
            columns={12}
            sx={{ mb: (theme) => theme.spacing(2) }}
          >
            <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              <CustomerInfoCard />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              <ItemInfoCard />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              <OrderInfoCard />
            </Grid>
            <Grid size={{ xs: 12, sm: 6, lg: 3 }}>
              <QuoteInfoCard />
            </Grid>

            <Grid size={{ sm: 12, md: 6 }}>
              <SessionsChart />
            </Grid>
            <Grid size={{ sm: 12, md: 6 }}>
              <PageViewsBarChart />
            </Grid>
          </Grid>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Latest Orders
          </Typography>
          <Grid columns={12}>
            <Grid size={{ md: 12, lg: 12 }}>
              <DashboardOrderList />
            </Grid>
          </Grid>
        </Grid>
        <Grid size={{ xs: 12, md: 12, lg: 3 }}>
          <Typography component="h2" variant="h6" sx={{ mb: 2 }}>
            Information
          </Typography>
          <Grid size={{ xs: 12 }} sx={{ mb: 2 }}>
            <MyTasksCard />
            <TextCard
              title="PoratlX App Update - v2.5.2"
              content="<p>
              New app features include shipping charges and a a new quick order feed.
            </p>
            <li>
              Quickly order add items straight from the item list without having to view more detail. 
            </li>
            <br />
            <li>Setup shipping charges for your customers</li>"
            />

            <TextCard
              title="Payment List"
              content="<p>
                            You can now <a href='/settings/payment-transactions'>view payment transactions</a> as an entire list. Purge pending payments from one place.
                        </p>"
            />
            <TextCard
              title="Collection Menus"
              content="<p>
  You can now <a href='/settings/collection-menu-groups'>setup collection group menus</a>
  for the Customer Portal and PortalX. Create a collection group, choose the
  collections then assign some users.
</p>"
            />
          </Grid>
        </Grid>
      </Grid>
      <Copyright sx={{ my: 4 }} />
    </Box>
  );
}
