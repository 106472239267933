import { Box, Chip, Drawer, IconButton, Typography } from "@mui/material";
import PaymentMethodDisplay from "../../Views/Customer/CustomerSpecific/PaymentMethodDisplay";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@emotion/react";
import { getEnumLabel } from "../../../utils";
import { DataGrid } from "@mui/x-data-grid";
import { paymentStatusMap, rowVersionMap, userStatusMap, userTypeMap, orderStatusMap, quoteStatusMap, invoiceStatusMap, orderStatusNumberMap, quoteStatusNumberMap, invoiceStatusNumberMap, paymentStatusNumberMap, userTypeNumberMap, userStatusNumberMap, rowVersionNumberMap } from "./StatusMaps";
import InventoryPerWarehouseDisplay from "../../Views/Item/ItemSpecific/InventoryPerWarehouseDisplay";


export const ReceivedByERPCell = ({ value }) => (
  <Chip
    label={value === 0 ? "No" : "Yes"}
    color={value === 0 ? "error" : "success"}
    size="small"
  />
);


export const AvailableForSaleCell = ({ value }) => (
  <Chip
    label={value === 0 ? "No" : "Yes"}
    color={value === 0 ? "error" : "success"}
    size="small"
  />
);

export const OrderStatusCell = ({ metaDataList, value }) => {
  if (metaDataList == null || value == null) {
    return <div />;
  }
  //Open,Confirmed,Released,Partially Released,Cancelled,Pending Prepayment,On Hold,Pending Approval,Completed
  let orderStatus = getEnumLabel("status", metaDataList, value);
  if (orderStatus === "") orderStatus = orderStatusNumberMap[value]?.label;
 

  try {
    const { backgroundColor, textColor } =
      orderStatusNumberMap[value] || orderStatusNumberMap[6];
    return (
      <Chip
        label={orderStatus || "Unknown"}
        color={backgroundColor}
        sx={{
          size: "small",
          color: textColor,
          variant: "contained",
          width: "140px",
          p: 1,
        }}
      />
    );
  } catch (error) {
    return <div />;
  }
};

export const QuoteStatusCell = ({ metaDataList, value }) => {
  if (metaDataList == null || value == null) {
    return <div />;
  }

  //Open,Won,Lost,Expired,Pending,Approved by Customer,
  const quoteStatus = getEnumLabel("status", metaDataList, value);
  if (quoteStatus === "") quoteStatus = quoteStatusNumberMap[value]?.label;
 


  try {
    const { backgroundColor, textColor } =
      quoteStatusNumberMap[value] || quoteStatusNumberMap[5];
    return (
      <Chip
        label={quoteStatus || "Unknown"}
        color={backgroundColor}
        sx={{
          size: "small",
          color: textColor,
          variant: "contained",
          width: "140px",
          p: 1,
        }}
      />
    );
  } catch (error) {
    return <div />;
  }
};

export const InvoiceStatusCell = ({ metaDataList, value }) => {
  if (metaDataList == null || value == null) {
    return <div />;
  }
  //Open,Invoiced,Cancelled,Pending
  const invoiceStatus = getEnumLabel("status", metaDataList, value);
  if (invoiceStatus === "") invoiceStatus = invoiceStatusNumberMap[value]?.label;

  try {
    const { backgroundColor, textColor } =
      invoiceStatusNumberMap[value] || invoiceStatusNumberMap[3];
    return (
      <Chip
        label={invoiceStatus || "Unknown"}
        color={backgroundColor}
        sx={{
          size: "small",
          color: textColor,
          variant: "contained",
          width: "140px",
          p: 1,
        }}
      />
    );
  } catch (error) {
    return <div />;
  }
};

export const PaymentStatusCell = ({ metaDataList, value, sx }) => {
  if (metaDataList == null || value == null) {
    return <div />;
  }
  //unpaid, paid_card, voided, partially_paid, paid_cash;
  //Awaiting Payment,Paid,Voided,Partially Paid,Paid in Cash
  let paymentStatus = getEnumLabel("payment_status", metaDataList, value);
  if (paymentStatus === "") paymentStatus = paymentStatusNumberMap[value]?.label;
 
  


  try {
    const { backgroundColor, textColor } =
      paymentStatusNumberMap[value] || paymentStatusNumberMap[0];
    return (
      <Chip
        label={paymentStatus}
        color={backgroundColor}
        sx={{
          ...sx,
          size: "small",
          color: textColor,
          variant: "contained",
          width: "140px",
          p: 1,
        }}
      />
    );
  } catch (error) {
    return <div />;
  }
};

export const PaymentStatusGridCell = ({ value }) =>
  value === 0 ? (
    <div />
  ) : (
    <Chip label="Paid" color="success" size="small" variant="outlined" />
  );

export const ActionTypeCell = ({ value }) => {
  let label = "";
  let color = "";

  
  switch (value) {
    case 0:
      label = "Insert";
      color = "success";
      break;
    case 1:
      label = "Update";
      color = "primary";
      break;
    case 2:
      label = "Delete";
      color = "danger";
      break;
    default:
      label = "";
      color = "";
  }

  return <Chip label={label} color={color} size="small" />;
};

export const RowVersionCell = ({ value }) => {
 

  const { label, color } = rowVersionNumberMap[value] || rowVersionNumberMap[7]; // Default to "Unknown" if value is not in the map

  return (
    <Chip
      label={label}
      color={color}
      sx={{
        size: "small",
        color: color,
        variant: "contained",
        width: "140px",
        p: 1,
      }}
    />
  );
};

export const DocumentDateCell = ({ value }) => {
  try {
    return value.substring(0, 16);
  } catch (error) {
    return value;
  }
};


export const PaymentMethodsCell = ({ value }) => {
  return <PaymentMethodDisplay inputString={value} />;
};

export const InventoryPerWarehouseCell = ({ value }) => {
  return <InventoryPerWarehouseDisplay  objectList={value} />;
};

export const CustomerTypeCell = ({ value }) => (
  <Chip
    label={value === 0 ? "B2B" : "B2C"}
    color={value === 0 ? "info" : "info"}
    size="small"
  />
);



export const ForSaleCell = ({ value }) => (
  <Chip
    label={value === 0 ? "No" : "Yes"}
    color={value === 0 ? "error" : "success"}
    size="small"
  />
);

export const AccountStatusCell = ({ value }) => {
 

  const { label, color } = userStatusNumberMap[value] || {
    label: "Unknown",
    color: "error",
  };

  return (
    <Chip
      label={label}
      color={color}
      size="small"
      sx={{ width: "80px", justifyContent: "center" }}
    />
  );
};


export const UserAccountStatusCell = ({ value }) => {


  const { label, color } = userStatusNumberMap[value] || {
    label: "Unknown",
    color: "error",
  };

  return (
    <Chip
      label={label}
      color={color}
      size="small"
      sx={{ width: "80px", justifyContent: "center" }}
    />
  );
};

export const UserTypeStatusCell = ({ value }) => {

  const { label, color } = userTypeNumberMap[value] || {
    label: "Unknown",
    color: "error",
  };

  return (
    <Chip
      label={label}
      color={color}
      size="small"
      sx={{ width: "80px", justifyContent: "center" }}
    />
  );
};

export const GetUserTypeChip = (value) => {


  const { label, color } = userTypeNumberMap[value] || {
    label: "Unknown",
    color: "error",
  };

  return (
    <Chip sx={{ p: 1, py: 2 }} label={label} variant="outlined" color={color} />
  );
};

export function ListBox({
  rows,
  columns,
  totalRows,
  loading,
  paginationModel,
  handlePaginationModelChange,
  pageSize,
  getRowId,
  handleRowClick,
  rowHeight = 40,
  headerHeight = 40,
}) {
  return (
    <Box style={{ width: "100%" }}>
      <DataGrid
        sx={{ fontSize: 12 }}
        rows={rows}
        columns={columns}
        paginationMode="server"
        rowCount={totalRows}
        loading={loading}
        paginationModel={paginationModel}
        onPaginationModelChange={handlePaginationModelChange}
        pageSizeOptions={[pageSize]}
        getRowId={getRowId}
        disableRowSelectionOnClick
        onRowClick={handleRowClick}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        disableColumnFilter={true}
        disableColumnSorting={true}
        disableColumnMenu={true}
        autoHeight={true}
      />
    </Box>
  );
}

export const DocumentStatusChips = ({
  documentStatus,
  filteredDocumentStatusMap,
  fullDocumentStatusMap = null,
  showAllFilters = false,
  setShowAllFilters = null,
  setDocumentStatus = null,
  showFilterButton = false,
}) => {
  let map = filteredDocumentStatusMap;

  if (showAllFilters) {
    map = fullDocumentStatusMap;
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "top",
        mb: 2,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          gap: 1,
          mb: 2,
          flexWrap: "wrap",
        }}
      >
        {Object.values(map).map(({ label, color, status }) => (
          <Chip
            key={label}
            label={label}
            color={color}
            onClick={() => setDocumentStatus(status)}
            sx={{ cursor: "pointer" }}
          />
        ))}

        <Typography variant="caption" color="text.secondary">
          Showing documents with status:{" "}
          <strong>
            {documentStatus === null
              ? "All"
              : Object.values(map).find((s) => s.status === documentStatus)
                  ?.label || "Unknown"}
          </strong>
        </Typography>
      </Box>
      {showFilterButton && (
          <Chip
          sx={{ alignItems: "center", mb: 2 }}
          variant="outlined"
          size="small"
          color="success"
          onClick={() => setShowAllFilters(!showAllFilters)}
          label={showAllFilters ? "Hide filters" : "Show more filters"}
        />
      )}
    </Box>
  );
};