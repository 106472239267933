import * as React from "react";
import {
  createTheme,
  ThemeProvider,
  alpha,
  styled,
} from "@mui/material/styles";

import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import getDashboardTheme from "../../theme/getDashboardTheme";
import AppNavbar from "./AppNavbar";
import Header from "./Header";
import MainGrid from "./MainGrid";
import SideMenu from "../Admin/SideMenuDrawer";
import TemplateFrame from "./TemplateFrame";
import ToggleColorMode from "../Admin/ToggleColorMode";
import { Alert, AppBar, Snackbar, Toolbar, Typography } from "@mui/material";
import { useAppState } from "../../reduxModule";
import MenuButton from "../Admin/MenuButton";
import SideMenuDrawerMobile from "../Admin/SideMenuDrawerMobile";
import SideMenuDrawer from "../Admin/SideMenuDrawer";
import Menu from "@mui/icons-material/Menu"

export default function Dashboard({ children }) {
  const [mode, setMode] = React.useState("light");
  const { sideMenuOpen, handleUpdateSideMenuOpen } = useAppState();

  React.useEffect(() => {
    const savedMode = localStorage.getItem("themeMode");
    if (savedMode) {
      setMode(savedMode);
    } else {
      const systemPrefersDark = window.matchMedia(
        "(prefers-color-scheme: dark)"
      ).matches;
      setMode(systemPrefersDark ? "dark" : "light");
    }
  }, []);

  const toggleColorMode = () => {
    const newMode = mode === "dark" ? "light" : "dark";
    setMode(newMode);
    localStorage.setItem("themeMode", newMode);
  };

  const toggleDrawer = (newOpen) => () => {
    handleUpdateSideMenuOpen(newOpen);
  };

  const StyledAppBar = styled(AppBar)(({ theme }) => ({
    position: "sticky",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    flexShrink: 0,
    borderBottom: "1px solid",
    borderColor: theme.palette.background.bottom,
    backgroundColor: "background",
    boxShadow: "none",
    backgroundImage: "none",
    zIndex: theme.zIndex.drawer - 1,
    flex: "0 0 auto",
    height: 56,
    margin: 0,
  }));

  const { loggedInUser } = useAppState();
  const dashboardTheme = createTheme(getDashboardTheme(mode));
  const { globalSnackbarOpen, globalSnackbarMessage, handleClearSnackbar } =
    useAppState();

  return (
    <TemplateFrame mode={mode} toggleColorMode={toggleColorMode}>
      <ThemeProvider theme={dashboardTheme}>
        <Snackbar
          open={globalSnackbarOpen}
          message={globalSnackbarMessage}
          autoHideDuration={3000}
          onClose={handleClearSnackbar}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          sx={{
            backgroundColor: "#828282",
            color: "#ffffff",
          }}
        ></Snackbar>
        <CssBaseline enableColorScheme />
        <Box sx={{ display: "flex" }}>
          <SideMenuDrawer />
          <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
            <StyledAppBar>
              <Toolbar
                variant="dense"
                disableGutters
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  p: "8px 12px",
                }}
              >
                <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                  <MenuButton
                    sx={{ display: { xs: "flex", lg: "none" } }}
                    aria-label="menu"
                    onClick={toggleDrawer(true)}
                  >
                    <Menu />
                  </MenuButton>
                  <Typography
                    variant="h5"
                    letterSpacing={2}
                    color="text.secondary"
                    sx={{ padding: 0.5 }}
                  >
                    SalesMachineX.com
                  </Typography>
                  <Typography
                    variant="p"
                    letterSpacing={2}
                    color="text.secondary"
                    sx={{ padding: 0 }}
                  >
                    / {loggedInUser.company_name}
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: 1 }}>
                  <ToggleColorMode
                    data-screenshot="toggle-mode"
                    mode={mode}
                    toggleColorMode={toggleColorMode}
                  />

                  <SideMenuDrawerMobile
                    open={sideMenuOpen}
                    toggleDrawer={toggleDrawer}
                  />
                </Box>
              </Toolbar>
            </StyledAppBar>
            <Box
              component="main"
              sx={(theme) => ({
                flexGrow: 1,
                backgroundColor: alpha(theme.palette.background.backdrop, 1),
                overflow: "auto",
                px: 3,
                py: 2,
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              })}
            >
              <Header />
              {children || <MainGrid />}
            </Box>
          </Box>
        </Box>
      </ThemeProvider>
    </TemplateFrame>
  );
}
