import React from "react";
import {
  Checkbox,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  Box,
} from "@mui/material";

const InventoryPerWarehouseDisplay = ({ objectList }) => {
  console.log("InventoryPerWarehouseDisplay received inputString:", objectList);




  if (objectList.length === 0) {
    return <Typography color="error">Invalid payment method data</Typography>;
  }

  return (
    <List disablePadding sx={{ mr: 0 }}>
      {objectList.map((warehouse, index) => (
        <ListItem key={index} disableGutters sx={{ m: 0, p: 0, justifyContent: "space-between" }}>
          <Box>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{ mr: 2, }}
                  style={{ fontSize: "0.8rem" }}
                >
                  <strong>{warehouse.warehouse_id}</strong>
                </Typography>
              }
            />
          </Box>
          <Box>
            <ListItemText
              primary={
                <Typography
                  variant="body2"
                  sx={{ mr: 0 }}
                  style={{ fontSize: "0.8rem" }}
                >
                  {warehouse.inventory}
                </Typography>
              }
            />
          </Box>
        </ListItem>
      ))}
    </List>
  );
};

export default InventoryPerWarehouseDisplay;
